import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { isLoggedIn } from '../services/auth.api';

export const HeaderOffer = ({ type }: { type?: string }) => {
  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={data => {
        const prismicContent =
          type === 'account-banner'
            ? data?.prismicAccountBanner?.data?.content?.raw
            : isLoggedIn()
            ? data?.prismicMainPageBannerLogin?.data?.content?.raw
            : data?.prismicMainPageBanner?.data?.content?.raw;
        return (
          <div className="hdr-info container-fluid">
            {RichText.render(prismicContent)}
          </div>
        );
      }}
    />
  );
};

const headerQuery = graphql`
  {
    prismicAccountBanner {
      data {
        content {
          raw
        }
      }
    }
    prismicMainPageBanner {
      data {
        content {
          raw
        }
      }
    }
    prismicMainPageBannerLogin {
      data {
        content {
          raw
        }
      }
    }
    prismicCartBanner {
      data {
        content {
          raw
        }
      }
    }
  }
`;
