import React from 'react';
import { Oval } from 'react-loader-spinner';

export default props => {
  return (
    <div
      style={{
        position: props.position || 'absolute',
        width: '100%',
        height: props.height || '100vh',
        background: 'transparent',
        top: props.topSpacing || 0,
        left: 0,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          background: '#fff',
          opacity: props.opacity || '0.5',
          zIndex: 100,
          top: 0,
          height: '100%',
        }}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          background: 'transparent',
          zIndex: 200,
          top: 0,
          height: '100%',
        }}
      >
        <Oval color="#f36e47" height={100} width={100} secondaryColor="#f36e47"/>
      </div>
    </div>
  );
};
